import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `StaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `StaticQuery`: https://gatsby.dev/staticquery
 */

function normalizeProps(props) {
  if (props.fluid && props.fluid.presentationWidth) {
    return {
      ...props,
      style: {
        ...(props.style || {}),
        width: "100%",
        maxWidth: props.fluid.presentationWidth,
        margin: "0 auto", // Used to center the image
        flexShrink: "0",
      },
    }
  }
  return props
}
const Logo = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
              presentationWidth
            }
          }
        }
      }
    `}
    render={data => (
      <Img {...normalizeProps(data.placeholderImage.childImageSharp)} />
    )}
  />
)
export default Logo
